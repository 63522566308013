#header {
  z-index: 2;
  background: black;
  overflow: hidden;
  min-height: 50vh;
  height: 50vh;
  //background-image: url("./images/header/header_Image.jpg");
  //background-size: cover;
  //background-repeat: no-repeat;
  //background-position: top center;

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
    height: 100vh;
  }

  .header_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./images/header/header_Image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 70% top;

    @include media-breakpoint-up(sm) {
      background-position: center top;
    }
    @include media-breakpoint-up(md) {
      background-position: center top;
    }
  }

  .container,
  .container-fluid{
    position: relative;
    padding-top: 0;
    min-height: 100%;
    height: 100%;

    .row {
      min-height: 100%;
      height: 100%;

      .col {
        min-height: 100%;
        height: 100%;
        padding-top: 0;

        .header_text_holder {
          display: none;
          z-index: 9;
          position: absolute;
          bottom: 8.333%;
          left: 0;
          width: 100%;
          padding: 50px 50px 20px 50px;
          background-color: white;
          min-height: 100px;

          .header_logo_holder {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 90%;
            max-width: 443px;

            .header_logo {
              position: relative;
              width: 100%;
              transform: translate(-10%, -75%) ;

              img {
                width: 100%;
                transform: rotate(-3deg);
              }
            }
          }



          @include media-breakpoint-up(sm) {
            //left: 8.333%;
            width: 58.33%;
          }
          @include media-breakpoint-up(md) {
            width: 50%;
            width: 58.33%;
          }
          @include media-breakpoint-up(lg) {
            width: 41.66%;
            width: 50%;
            display: block;
          }
          @include media-breakpoint-up(xl) {
            //padding: 4.1666% 8.333%;
            width: 33.33%;
            width: 41.66%;
          }
        }

        .header_image_holder {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          transition: 2s;
          transform: scale(1.1);

          &.show {
            transform: scale(1);
          }

          /*
          .header_image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("./images/header/header_Image.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
          }
          */
        }
      }
    }
  }
}

@-webkit-keyframes animate_arrow_down {
  0% {
    -webkit-transform:  translate(0, 0);
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0px, 20px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 20px);
    opacity: 0;
  }
}
@keyframes animate_arrow_down {
  0% {
    -webkit-transform:  translate(0, 0);
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0px, 20px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 20px);
    opacity: 0;
  }
}

