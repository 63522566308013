#intro {
  z-index: 1;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  background: rgba(0, 0, 0, 1);
  transform: translateY(0) !important;

  .intro_container {
    position: relative;
    transition: 1s;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .intro_text_container {

      .intro_text_container_01 {
        position: relative;

        #intro_text_01{
          color: white;
          -webkit-transform: translate3d(0, 0, 0);

          &.show {
            opacity: 1;
          }

          .intro_letter_holder {
            position:relative;
            display:inline-block;

            .intro_letter {
              opacity: 0;
              position: relative;
              display: inline-block;
            }
          }
        }
        #intro_text_01 div{
          -webkit-font-smoothing: antialiased;
          -moz-font-smoothing:antialiased;
        }

        .intro_logo {
          position: absolute;
          display: block;
          width: 62%;
          height: auto;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          //transition: 1s;
          transform-origin: center center;
          opacity: 0;

          img {
            width: 100%;
          }
        }
      }

      .intro_text_container_02 {

        #intro_text_02{
          opacity: 0;

          &.show {
            opacity: 1;
          }
        }
        #intro_text_02 div{
          -webkit-font-smoothing: antialiased;
          -moz-font-smoothing:antialiased;
        }
      }
    }
  }

  .intro_arrow_holder {
    position: absolute;
    width: 53px;
    left: 50%;
    bottom: 20px;
    margin-left: -26px;

    .intro_arrow{
      width: 100%;

      .scroll_arrow {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}

