//! import google fonts
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
// OR use @font-face like below


/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
  url('./fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
  url('./fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/roboto-v30-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/roboto-v30-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/roboto-v30-latin-900.woff') format('woff'), /* Modern Browsers */
  url('./fonts/roboto-v30-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/roboto-v30-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}


//! set font variables
$font-heading: 'Roboto', sans-serif;
$font-body: 'Roboto', sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 16px;
  color: $black;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }
  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
  }
}

h1, .h1 {
  font-family: $font-heading, serif;
  font-size: 30px;
  line-height: 26px;
  font-weight: 700;
  color: black;
  margin: 0;
  transform-origin: left center;

  font-size: 3.6em;
  line-height: .9em;

  @include media-breakpoint-up(sm) {
    font-size: 4em;
    line-height: .8em;
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {
    //font-size: 75px;
    //line-height: 65px;
  }

  &.big {
    font-size: 5em;
    line-height: 1em;
  }

  &.padding_bottom {
    padding-bottom: 20px;

    @include media-breakpoint-up(sm) {
      padding-bottom: 20px;
    }
    @include media-breakpoint-up(md) {
      padding-bottom: 30px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 40px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 50px;
    }
  }
}
h2 {
  font-family: $font-heading, serif;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: $green;
  margin: 0;
  padding-bottom: 30px;
  font-size: 1.66em;
  line-height: 1em;
}
h3 {
  font-family: $font-heading, serif;
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 30px;

  font-size: 1.5em;
  line-height: 1.5em;

  font-size: 1.4em;
  line-height: 1.4em;
}
h4 {
  font-family: $font-heading, serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-bottom: 20px;

  font-size: 1em;
  line-height: 1.5em;
}
p, a {

  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: black;
  margin: 0;
  padding-bottom: 10px;

  font-size: 1em;
  line-height: 1.5em;
  transform-origin: left center;

  @include media-breakpoint-up(sm) {
    font-size: .95em;
    line-height: 1.5em;
  }
  @include media-breakpoint-up(md) {
    font-size: 1em;
    line-height: 1.5em;
  }

  &.header {
    line-height: 1.0em;
}

  &.big {
    font-size: 1.5em;
    line-height: 1.2em;

    @include media-breakpoint-up(sm) {

    }
    @include media-breakpoint-up(md) {
      font-size: 2em;
      line-height: 1.2em;
    }
    @include media-breakpoint-up(lg) {

    }
    @include media-breakpoint-up(xl) {
    }
  }
  &.small {
    font-size: 0.9em;
    line-height: 1.1em;
  }
}

a {
  cursor: pointer;
}

a:hover {
  color: $green;
}