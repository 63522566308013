.modal-dialog {

  .modal-content{
    background-color: $green;

    .modal-body {
      position:relative;
      padding: 60px 0;
      padding: 0px 0;
      background-color: rgba(000,000,000,.0);
      display: flex;
      justify-content: center;
      align-items: center;

      .container,
      .text_holder {
        position: relative;
        //background-color: white;
        width: 90%;
        height: auto;
        padding: 40px;
        margin: auto;
        padding-top: 150px;

        .modal_text_button_close {
          z-index: 10;
          position: fixed;
          top: 20px;
          right: 20px;
          height: 60px;
          width: 60px;
          border-radius: 30px;
          cursor: pointer;
          margin-right: 0px;
          transition: 1000ms;

          @include media-breakpoint-up(sm) {
            right: 50px;
            top: 50px;
          }

          .burger_holder {
            position: relative;
            width: 40px;
            height: 26px;
            margin-left: 15px;
            margin-top: 18px;
            transition: .5s;

            span{
              background: white;
              border: none;
              height: 3px;
              width: 100%;
              width: 0;
              position: absolute;
              top: 0;
              left: 0;
              transition: all 0.35s ease;
              cursor: pointer;
              border-radius: 0px;
              transform-origin: center center;

              &.top {
                transform: translateY(11px) translateX(0) rotate(45deg);
                background: black;
                left: 0;
                width: 100%;
              }

              &.bottom {
                transform: translateY(-11px) translateX(0) rotate(-45deg);
                background: black;
                top: 22px;
                left: 0;
                width: 100%;
              }
            }


            &:hover{
              transform: rotate(90deg);
            }
          }
        }

        h2 {
          color: black;
          margin-left: 0px !important;
        }
        h3 {
          color: black;
          padding-top: 30px;
          padding-bottom: 10px;
        }
        p {
          color: black;
        }

        .ro {

          .col {
            display: flex;
            justify-content: center;
            align-items: center;

            .modal_text {
              padding-left: 0px;

              .modal_headline_top,
              .modal_headline_bottom{
                display: flex;
                width: auto;

                h3 {
                  padding-bottom: 5px;
                }

                .leistungen_item_icon {
                  width: 30px;

                  .circle_blue_big {
                    transition: 0.5s;
                    left: -30px;
                  }
                  .circle_blue_small {
                    transition: 0.5s;
                    transform-origin: 0 0;
                  }

                  img, svg {
                    width: 30px;
                    margin: 0 auto;
                    margin-top: -30px;
                    margin-left: 5px;
                  }
                }
              }
              .modal_headline_bottom {
                padding-bottom: 20px;
              }

              @include media-breakpoint-up(lg) {
                padding-left: 50px;
              }
            }
          }
        }
      }
    }
  }
}


.fade_from_bottom {
  transform: translateY(110%);
  opacity: 1 !important;
  -webkit-transition: .5s opacity, 1s all 0s;
  -moz-transition: .5s opacity, 1s all 0s;
  -ms-transition: .5s opacity, 1s all 0s;
  -o-transition: .5s opacity, 1s all 0s;
  transition: .5s opacity, 1s all 0s;
  display: block !important;
}

.fade_from_bottom.show {
  opacity: 1;
  transform: translateY(0) !important;
  transform:none;
}

.modal-backdrop {
  background-color: transparent;
}