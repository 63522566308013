#teamed {
  z-index: 3;
  background:black;
  overflow: hidden;

  .container,
  .container-fluid{
    position: relative;
    //min-height: 100%;
    //border: 2px solid green;

    .row_images {
      padding-top: 0;
    }

    .row {

      .col {

        .teamed_images {
          position: relative;
          margin-left: 0;
          //border: 2px solid red;
          //min-height: 100px;

          @include media-breakpoint-up(lg) {

          }

          .teamed_image_container {
            position: relative;
            width: 100%;

            @include media-breakpoint-up(lg) {
              position: absolute;
            }

            &.teamed_image_container_01 {
              display: flex;
              align-items: center;
              justify-content: center;
              justify-content: flex-start;

              .teamed_image_01 {
                width: 38.6vw;
              }

              @include media-breakpoint-up(lg) {
                justify-content: left;

                .teamed_image_01 {
                  width: 15.2%;
                  margin-left: 1vw;
                }
              }
            }

            &.teamed_image_container_02 {
              top: 0;
              right: 0%;
              transform: translateY(-250%);
              display: flex;
              align-items: baseline;
              justify-content: flex-end;

              .teamed_image_02{
                width: 34.5vw;
                margin-left: 0vw;
              }

              @include media-breakpoint-up(lg) {
                top: 0;
                transform: translateY(360%);
                justify-content: flex-start;

                .teamed_image_02 {
                  width: 18.7%;
                  margin-left: 0vw;
                }
              }
            }

            &.teamed_image_container_03 {
              z-index: 3;
              display: flex;
              transform: translateY(0%);
              justify-content: center;
              justify-content: flex-start;

              .teamed_image {
                width: 21.1vw;
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                top: 0;
                left: 20%;
                transform: translateY(-20%);

                .teamed_image {
                  width: 11%;
                }
              }
            }

            &.teamed_image_container_04 {
              display: flex;
              transform: translateY(-125%);
              right: 10vw;
              justify-content: flex-end;

              .teamed_image {
                width: 49.3vw;
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                top: 10vw;
                top: 0;
                left: 20%;

                transform: translateY(100%);

                .teamed_image {
                  width: 19%;
                }
              }
            }

            &.teamed_image_container_05 {
              z-index: 2;
              display: flex;
              transform: translateY(-90%);
              justify-content: center;

              .teamed_image {
                width: 59vw;
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                top: 0;
                transform: translateY(-65%);
                left: 33%;

                .teamed_image {
                  width: 24.4%;
                }
              }
            }

            &.teamed_image_container_06 {
              z-index: 1;
              display: flex;
              transform: translateY(-100%);
              top: 0;
              right: 5%;
              justify-content: flex-end;

              .teamed_image {
                width: 29.2vw;
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                top: 0;
                transform: translateY(-50%);
                right: 0vw;
                left: 62%;

                .teamed_image {
                  width: 18.2%;
                }
              }
            }

            &.teamed_image_container_07 {
              z-index: 2;
              display: flex;
              top: 0;
              left: 5%;
              transform: translateY(-750%);
              justify-content: flex-start;

              .teamed_image {
                width: 39.7vw;
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                right: 0vw;
                left: 45%;
                top: 0;
                transform: translateY(350%);

                .teamed_image {
                  width: 21%;
                }
              }
            }

            &.teamed_image_container_08 {
              z-index: 2;
              display: flex;
              transform: translateY(-150%);
              justify-content: flex-start;

              .teamed_image {
                width: 39.3vw;
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                top: -6vw;
                top: 0;
                left: 42%;
                transform: translateY(150%);

                .teamed_image {
                  width: 18.2%;
                }
              }
            }

            &.teamed_image_container_09 {
              display: flex;
              transform: translateY(-170%);
              justify-content: flex-end;
              right: 10vw;

              .teamed_image {
                width: 25.6vw;
              }

              @include media-breakpoint-up(lg) {
                top: 0;
                right: 2vw;
                transform: translateY(50%);

                .teamed_image {
                  width: 12.9%;
                }
              }
            }

            &.teamed_image_container_10 {
              z-index: 1;
              display: flex;
              transform: translateY(-250%);
              justify-content: flex-start;
              left: 5vw;

              .teamed_image {
                width: 32.6vw;
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                top: 0;
                left: 63%;
                transform: translateY(110%);

                .teamed_image {
                  width: 19.2%;
                }
              }
            }

            &.teamed_image_container_11 {
              display: flex;
              transform: translateY(-460%);
              justify-content: center;
              left: 5vw;

              .teamed_image {
                width: 22.5%;
              }

              @include media-breakpoint-up(sm) {
                transform: translateY(-500%);
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-end;
                top: 0;
                left: 0%;
                transform: translateY(-90%);

                .teamed_image {
                  width: 14.3%;
                }
              }
            }

            &.teamed_image_container_12 {
              top: 0;
              left: -20%;
              transform: translateY(-680%);
              display: flex;
              align-items: baseline;
              justify-content: flex-end;

              .teamed_image_12{
                width: 60vw;
                margin-left: 0vw;
              }
              @include media-breakpoint-up(sm) {
                transform: translateY(-630%);
              }
              @include media-breakpoint-up(md) {
                transform: translateY(-600%);
              }
              @include media-breakpoint-up(lg) {
                top: 0;
                left: 3%;
                transform: translateY(550%);
                justify-content: flex-start;

                .teamed_image_12 {
                  width: 30%;
                  margin-left: 0vw;
                }
              }
            }

            .teamed_image {

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

