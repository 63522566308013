#service {
  z-index: 2;
  background:$green;
  overflow: hidden;

  .container{
    position: relative;
    min-height: 100%;
    padding-bottom: 100px;

    .row {
      min-height: 100%;
      //height: 100vh;

      .col {
        min-height: 100%;

        .service_carossel {
          padding-bottom: 100px;
          padding-bottom: 0px;

          .service_slide {
            width: 100%;
            padding-bottom: 20px;
            //border: 1px solid red;

            @include media-breakpoint-up(sm) {
              //width: 50%;
            }
            @include media-breakpoint-up(md) {
              //width: 33%;
            }
            @include media-breakpoint-up(lg) {
              //width: 25%;
            }
            @include media-breakpoint-up(xl) {
             // width: 25%;
            }

            .service_icon {
              width: 80%;
              margin-left: 10%;


              img {
                width: 100%;
              }
            }

            .service_text {
              padding: 0 10%;
            }
          }
        }
      }
    }
  }
}

.slick-dots {

  li button::before {
    font-size: 20px;
    color: black;
    opacity: 1;
    transition: color .5s;
  }
  li.slick-active button::before {
    color: white;
  }

  li button:hover::before,
  li button:focus::before {
    color: white;
  }
}