.grid {
  z-index: 999;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: none;

  .debug {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 50px;
    height: 50px;
    padding: 10px;
    background-color: white;
  }

  .grid_item{
    position: absolute;
    top: 0px;
    left: 8.3333%;
    width: 1px;
    height: 100%;
    background-color: rgba(255, 0, 0, 0);

    &.grid_item_01 {left: 8.3333%;}
    &.grid_item_02 {left: 16.6666%;}
    &.grid_item_03 {left: 25%;}
    &.grid_item_04 {left: 33.3333%;}
    &.grid_item_05 {left: 41.6666%;}
    &.grid_item_06 {left: 50%;}
    &.grid_item_07 {left: 58.33333%;}
    &.grid_item_08 {left: 66.6666%;}
    &.grid_item_09 {left: 75%;}
    &.grid_item_10 {left: 83.333333%;}
    &.grid_item_11 {left: 91.6666%;}
  }
}

.section {
  position: relative;
  background-color: black;
  overflow-x: hidden;

  .container,
  .container-fluid{
    padding-right: 20px;
    padding-left: 20px;

    @include media-breakpoint-up(sm) {
      padding-right: 0;
      padding-left: 0;
    }
    //padding-top: 250px;



    .row {
      margin-right: 0;
      margin-left: 0;
      padding-top: 5em;

      .col {
        padding-right: 0;
        padding-left: 0;

        /////padding-top: 5em;

        border: 1px solid red;
        border: 0px solid transparent !important;

        @include media-breakpoint-up(sm) {
          border: 1px solid yellow;
        }
        @include media-breakpoint-up(md) {
          border: 1px solid green;
        }
        @include media-breakpoint-up(lg) {
          border: 1px solid blue;
        }
        @include media-breakpoint-up(xl) {
          border: 1px solid red;
        }

      }
    }
  }
}

.rocket_holder {

  .rocket {
    position: fixed;
    z-index: 99999;
    bottom: -100px;
    //left:0;
    transform: translateX(-50%);
    transition: 2s ease-out;
    opacity: 1;

    &.show {
      opacity: 1;
      bottom: 101vh !important;
    }

  }
}

#intro,
#header,
#about,
#service {
  //display: none;
}
