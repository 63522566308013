#contact {
  z-index: 2;
  background:black;
  overflow: hidden;

  .container{
    position: relative;
    //min-height: 100%;
    //min-height: 100vh;

    .row {
      //min-height: 100vh;
      //min-height: 100%;

      .col {

        a {
          transition: color .5s;

          &:hover {
            color: white;
          }
        }


        a.footer_link {

          &:hover {
            color: $green !important;
          }
        }



        .contact_mail_info,
        .contact_phone_info,
        .contact_office_info{
          overflow: hidden;
          height: 0;

          &.show {
            height: auto;
          }
        }

        .contact_office_info {
          display: flex;
          align-items: flex-start;

          .contact_office {
            //padding-right: 50px;
            display: inline-block;
            display: block;

            padding-top: 10px;
            max-width: 49%;

            .contact_office_image {
              width: 100%;
              max-width: 293px;
              //margin-right: 20px;

              img {
                width: 100%;
              }
            }

            .contact_office_image_01,
            .contact_office_text_01{
              padding-right: 10px;

              @include media-breakpoint-up(sm) {
                padding-right: 10px;
              }
              @include media-breakpoint-up(md) {
                padding-right: 15px;
              }
              @include media-breakpoint-up(lg) {
                padding-right: 20px;
              }
              @include media-breakpoint-up(xl) {
                padding-right: 25px;
              }
            }
            .contact_office_image_02,
            .contact_office_text_02{
              padding-left: 10px;

              @include media-breakpoint-up(sm) {
                padding-left: 10px;
              }
              @include media-breakpoint-up(md) {
                padding-left: 15px;
              }
              @include media-breakpoint-up(lg) {
                padding-left: 20px;
              }
              @include media-breakpoint-up(xl) {
                padding-left: 25px;
              }
            }

            .contact_office_text {
              padding-top: 10px;
              padding-right: 5px;
            }
          }
        }

        .contact_links {
          padding-top: 200px;
          padding-bottom: 20px;

          a,p {
            display: inline-block;
          }
        }
      }
    }
  }
}

