#about {
  z-index: 2;
  background:$green;
  overflow: hidden;

  .container{
    position: relative;
    //min-height: 100%;

    .row {
      //min-height: 100%;
      //height: 100vh;

      .col {
        //min-height: 100%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

